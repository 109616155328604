import { Box } from "@mui/material"
import React, { memo } from "react"
import { CarerAccrualDetailStatsInfo } from "./CarerAccrualDetailStatsInfo"
import { ApiContractAccrualSummary } from "data/finance/carer/types"
import { CarerAccrualDetailStatsRecalc } from "./CarerAccrualDetailStatsRecalc"

interface Props {
  carerGuid: string
  accrualSummary: ApiContractAccrualSummary
  accrualGuid: string
}

export const CarerAccrualDetailStats = memo(({ carerGuid, accrualGuid, accrualSummary }: Props) => {
  return (
    <Box display="flex">
      <CarerAccrualDetailStatsInfo accrualSummary={accrualSummary} />
      <CarerAccrualDetailStatsRecalc accrualSummary={accrualSummary} accrualGuid={accrualGuid} carerGuid={carerGuid} />
    </Box>
  )
})

CarerAccrualDetailStats.displayName = "CarerAccrualDetailStats"
