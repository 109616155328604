import { Box, Grid, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { FormSectionHeader } from "components/Form"
import { useCarerAccrualDetailQuery } from "data/finance/carer/queries"
import { DateTimeFormat } from "lib/datetime"
import { timeSpanToValuePeriod } from "lib/timespan"
import { ApiUserCarer } from "models"
import React, { memo } from "react"
import { CarerAccrualDetailList } from "./CarerAccrualDetailList"
import { CarerAccrualDetailStats } from "./CarerAccrualDetailStats"
import { useParams } from "react-router-dom"

interface Props {
  carer: ApiUserCarer
}

export const CarerAccrualDetail = memo(({ carer }: Props) => {
  const { id: accrualGuid } = useParams()

  const { data: accrualSummary } = useCarerAccrualDetailQuery({
    carerGuid: carer.guid,
    accrualGuid
  })

  if (!accrualGuid) return null

  return (
    <Box my={3}>
      <FormSectionHeader
        title="Annual Leave Accrual"
        action={
          accrualSummary ? (
            <CarerAccrualDetailStats accrualSummary={accrualSummary} carerGuid={carer.guid} accrualGuid={accrualGuid} />
          ) : undefined
        }
      />
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item md={5}>
          <Typography variant="body2" color="textSecondary">
            Accrual Policy
          </Typography>
          <Typography variant="h6">
            {accrualSummary?.policy?.contract.title}{" "}
            <Typography component="span" sx={{ color: grey[400] }}>
              ({accrualSummary?.policy?.start.toFormat(DateTimeFormat.DATE)} -{" "}
              {accrualSummary?.policy?.end ? accrualSummary.policy.end.toFormat(DateTimeFormat.DATE) : "--/--/--"})
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="body2" color="textSecondary">
            Accrual Cap
          </Typography>
          <Typography variant="h6">{accrualSummary?.policy?.accrual_cap}</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Rollover Cap
          </Typography>
          <Typography variant="h6">
            {accrualSummary?.policy?.rollover_cap}
            {accrualSummary?.policy?.rollover_expiry
              ? ` (use within ${timeSpanToValuePeriod(accrualSummary.policy.rollover_expiry)[0]} days)`
              : null}
          </Typography>
        </Grid>
      </Grid>

      <CarerAccrualDetailList carer={carer} accrualGuid={accrualGuid} />
    </Box>
  )
})

CarerAccrualDetail.displayName = "CarerAccrualDetail"
