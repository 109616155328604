import React, { memo, useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Auth, { AUTH_TYPE_GOOGLE } from "lib/Auth"
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { hasGuid, isGuid } from "helpers/guidHelper"
import { Permission } from "constants/permission"
import { BranchesListResponse } from "data/core/operator/queries"
import { getBranchTitle } from "./helpers/getBranchTitle"
import { useInvalidateAllSchemas } from "data/schema/useInvalidateAllSchemas"
import UserPreferences from "lib/UserPreferences"
import { useChatStore } from "pages/Chat/useChatStore"
import coreApi from "api/core"
import useResetBranchData from "lib/hooks/branchInfo/useResetBranchData"
import testingId from "constants/testingId"
import { IdentityApi } from "api"
import { DcpAuthResponse } from "api/instance"
import { useCoreSchemasStore } from "stores"
import { useAuthStore } from "stores/useAuthStore"

interface Props {
  branches: BranchesListResponse[]
}

export const MainNavBranchSelect = memo(({ branches }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const setCurrentChannel = useChatStore(({ setCurrentChannel }) => setCurrentChannel)
  const { resetBranchQueryData } = useResetBranchData()
  const fetchCoreSchemas = useCoreSchemasStore((state) => state.fetchCoreSchemas)
  const setUser = useAuthStore((state) => state.setUser)

  const invalidateAllSchemas = useInvalidateAllSchemas()

  const userBranchGuid = Auth.getUserInfo()?.branchGuid

  const selectedBranchGuid = useMemo(
    () => Auth.getSessionBranch() ?? branches.find((element) => element.guid === userBranchGuid)?.guid,
    [branches, userBranchGuid]
  )

  const branchOptions = useMemo(
    () =>
      branches.map((branch) => ({
        title: getBranchTitle(branch),
        value: branch.guid
      })),
    [branches]
  )

  const isDeveloper = Auth.hasPermission([Permission.DEVELOPER_EDIT])

  const newPath = useMemo(() => {
    if (!isDeveloper) return ""
    const path = location.pathname.split("/").reduce(
      ([newPathResult, guidFound], part) => {
        if (part) {
          if (isGuid(part) || guidFound) {
            return [newPathResult, true]
          }
          return [newPathResult + "/" + part, false]
        }
        return [newPathResult, false]
      },
      ["", false]
    )[0]

    const newSearch = path === location.pathname && !hasGuid(location.search) ? location.search : ""
    return path + newSearch
  }, [location, isDeveloper])

  const handleBranchChange = useCallback(
    async (event: SelectChangeEvent<string>) => {
      const {
        target: { value: branch }
      } = event
      const refresh_token = Auth.getRefreshToken()

      if (isDeveloper) {
        window.sessionStorage.setItem("originPath", newPath)
      }

      if (refresh_token && branch) {
        const authType = Auth.getAuthType()
        if (authType === AUTH_TYPE_GOOGLE) {
          const identityToken = Auth.getIdentityToken()
          const { accessToken } = await IdentityApi.get<void, DcpAuthResponse>(`/v1/auth/dcp?branch=${branch}`, {
            headers: { Authorization: `Bearer ${identityToken}` }
          })
          Auth.setAccessToken(accessToken)
          setUser(accessToken)
        } else {
          const { access_token } = await coreApi.refresh({ refresh_token, branchId: branch })
          Auth.setAccessToken(access_token)
          setUser(access_token)
        }
        Auth.saveSessionBranch(branch)
        UserPreferences.saveLastSelectedBranch(branch)
        resetBranchQueryData()
        invalidateAllSchemas()
        navigate("/", { replace: true })
        setCurrentChannel(undefined)
        fetchCoreSchemas()
      }
    },
    [
      newPath,
      isDeveloper,
      navigate,
      invalidateAllSchemas,
      setCurrentChannel,
      resetBranchQueryData,
      fetchCoreSchemas,
      setUser
    ]
  )

  return (
    <Box
      sx={{
        mx: 1
      }}
    >
      <FormControl fullWidth margin="normal" variant="outlined" data-cy={testingId.select}>
        <InputLabel
          sx={{
            color: "primary.contrastText"
          }}
          htmlFor="branch"
        >
          Branch
        </InputLabel>

        <Select
          name="branch"
          variant="outlined"
          onChange={handleBranchChange}
          inputProps={{
            id: "main-nav-branch-select"
          }}
          label="Branch"
          value={selectedBranchGuid}
          sx={{
            color: "primary.contrastText",
            fontSize: "caption.fontSize",
            ".MuiSelect-icon": { color: "primary.contrastText" },
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.contrastText"
              }
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.contrastText"
            },
            ".MuiOutlinedInput-notchedOutline legend": {
              fontSize: "caption.fontSize"
            },
            ".MuiSelect-select:focus": {
              backgroundColor: "rgba(0,0,0,0.05)"
            }
          }}
        >
          {branchOptions.map((item) => (
            <MenuItem key={item.value} value={item.value} data-cy={testingId.selectOption}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
})

MainNavBranchSelect.displayName = "MainNavBranchSelect"
