import { Box } from "@mui/material"
import { DatePicker } from "components/Form"
import { Permission } from "constants/permission"
import { useCalculateAccrualMutation } from "data/finance/development/mutations"
import Auth from "lib/Auth"
import { DateTime } from "luxon"
import React, { memo, useCallback, useState } from "react"
import { CarerAccrualDetailStatsModal } from "pages/Carers/CarerAccrual/CarerAccrualDetailStatsModal"
import { ApiContractAccrualSummary } from "data/finance/carer/types"
import { DevButton } from "components/DevButton"
import { ApiError } from "stores/useUIStore/types"
import { useApiMessage } from "stores"
import { useQueryClient } from "react-query"
import { queryKeys } from "data/finance/carer/queries"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

interface Props {
  carerGuid: string
  accrualSummary: ApiContractAccrualSummary
  accrualGuid: string
}

interface FormValues {
  start: DateTime
}

const recalcFormResolver: yup.ObjectSchema<FormValues> = yup.object({
  start: yup
    .mixed<DateTime>()
    .nullable()
    .required("This field is required")
    .test({
      name: "is-valid-date",
      message: "Enter a valid date",
      test: (value) => !!value && value.isValid
    })
})

export const CarerAccrualDetailStatsRecalc = memo(({ carerGuid, accrualGuid, accrualSummary }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const { showErrorMessage } = useApiMessage()
  const queryClient = useQueryClient()

  const destroyModal = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const onSuccess = useCallback(() => {
    setShowModal(true)
    queryClient.invalidateQueries(queryKeys.getCarerAccrualSummaryKey({ carerGuid, accrualGuid }))
  }, [setShowModal, queryClient, carerGuid, accrualGuid])

  const onError = useCallback(
    (apiError: ApiError) => {
      showErrorMessage("Something went wrong with recalculating accrual stats.", { apiError })
    },
    [showErrorMessage]
  )

  const { mutate: recalculate, data: accrualStats } = useCalculateAccrualMutation({ onSuccess, onError })

  const onSubmit = useCallback(
    (values: FormValues) => {
      recalculate({ carer_guid: carerGuid, fromDate: values.start })
    },
    [recalculate, carerGuid]
  )

  const form = useForm<FormValues>({
    resolver: yupResolver(recalcFormResolver),
    mode: "onChange",
    defaultValues: { start: accrualSummary.policy?.start }
  })

  const { handleSubmit, control, formState } = form

  if (!Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])) {
    return null
  }

  return (
    <Box display="flex">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="start"
            control={control}
            render={({ field, fieldState }) => {
              return <DatePicker input={field} fieldState={fieldState} />
            }}
          />
          <Box>
            <DevButton type="submit" disabled={!formState.isValid}>
              Recalc Stats
            </DevButton>
          </Box>
        </form>
      </FormProvider>

      {showModal && !!accrualStats && (
        <CarerAccrualDetailStatsModal onCancel={destroyModal} accrualStats={accrualStats} />
      )}
    </Box>
  )
})

CarerAccrualDetailStatsRecalc.displayName = "CarerAccrualDetailStatsRecalc"
