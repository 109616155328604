import Auth, { DecodedAccessToken, UserPermission } from "lib/Auth"
import jwt_decode from "jwt-decode"
import { create } from "zustand"

export interface AuthState {
  readonly is_authenticated: boolean
  readonly branch_guid: string
  readonly branch_guids: string[]
  readonly user_guid: string
  readonly user_type: string
  readonly user_permissions: UserPermission[]
  readonly user_name: string
  readonly permissions: string[]
  readonly permission_group: string[]
  readonly build: string
  readonly env: string
  readonly exp: number
}

interface AuthStore {
  user?: AuthState
  logIn: (tokens: { accessToken: string; refreshToken?: string; authType: string }) => void
  setUser: (accessToken: string) => void
  logOut: () => void
}

const getUserAuth = (accessToken: string) => {
  const user = jwt_decode<DecodedAccessToken>(accessToken)

  return {
    is_authenticated: true,
    branch_guid: user.branch_guid,
    branch_guids: user.branch_guids,
    user_guid: user.sub,
    user_type: user.user_type,
    user_permissions: user.role.map((role) => {
      const [permission, scope] = role.split(":")
      return { permission, scope, resource: "" }
    }),
    user_name: user.unique_name,
    permissions: user.role,
    permission_group: user.group,
    build: user.build,
    env: user.env,
    exp: user.exp
  }
}

export const useAuthStore = create<AuthStore>((set) => ({
  setUser: (accessToken) => {
    set(() => ({
      user: getUserAuth(accessToken)
    }))
  },
  logIn: ({ accessToken, refreshToken, authType }) => {
    Auth.setAccessToken(accessToken)
    Auth.setAuthType(authType)

    if (refreshToken) {
      Auth.setRefreshToken(refreshToken)
    }
    set(() => ({
      user: getUserAuth(accessToken)
    }))
  },
  logOut: () => {
    Auth.removeAccessToken()
    Auth.removeRefreshToken()
    Auth.removeIdentityToken()
    Auth.removeSessionBranch()
    Auth.removeAuthType()

    set(() => ({ user: undefined }))
  }
}))
