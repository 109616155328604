import { FinanceApi, NewFinanceApi } from "api"
import { DateTime } from "luxon"
import { useMutation, UseMutationResult } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { recalcTypes } from "./data"
import { useApiMessage } from "stores"
import { MutationCallbacks } from "data/types"

// https://dev.azure.com/CeraCare/DCP/_git/finance/pullrequest/21203?_a=files&path=/Cera.Sdk.Dcp.Finance/Models/Requests/ApiProcessInstruction.cs

interface RecalculateProps {
  type: string
  relation_guids: string[]
}

interface RewindProps {
  rewind_type: string
  item_guids: string[]
}

interface ReplaceFileProps {
  transaction_guids: string[]
  isInvoice: boolean
}

interface CalculateAccrualProps {
  carer_guid: string
  fromDate: DateTime
}

export interface AccrualStatsData {
  accrued: number
  allowance: number
  cap: number
  manual_additions: number
  remaining: number
  rollover: number
  used: number
}

export interface AccrualStats {
  data: AccrualStatsData
  success: boolean
  error?: {
    message: string
    stack_trace: string
  }
}

export const useRewindMutation = ({
  rewind_type,
  item_guids,
  onSettled,
  refetch
}: RewindProps & { onSettled: () => void; refetch: () => void }): UseMutationResult<void, ApiError, RewindProps> => {
  const typeMessageToShow = `${rewind_type.toLowerCase()}${item_guids.length > 1 ? "s" : ""}`
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (props): Promise<void> => {
      return FinanceApi.patch("/finance/tools/rewind?dryRun=false", props)
    },
    {
      onSuccess: () => {
        showSuccessMessage(`Rewind of ${typeMessageToShow} successful.`)
        refetch()
      },
      onError: (apiError: ApiError) => {
        showErrorMessage(`Something went wrong attempting to rewind ${typeMessageToShow}`, { apiError })
      },
      onSettled
    }
  )
}

export const useRecalculateMutation = (): UseMutationResult<void, ApiError, RecalculateProps[]> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const getSourceType = (source: string) =>
    recalcTypes.find((type) => type.toLowerCase().includes(source?.toLowerCase()))

  return useMutation(
    async (props: RecalculateProps[]) => {
      const payload = props.map(({ type, relation_guids }) => ({
        type: getSourceType(type),
        relation_guids
      }))

      return FinanceApi.patch(`/finance/tools/process`, payload)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Recalculate request sent.")
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Something went wrong with the recalculate request.", { apiError })
      }
    }
  )
}

export const useReplaceFileMutation = (): UseMutationResult<void, ApiError, ReplaceFileProps> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (props: ReplaceFileProps) => {
      return FinanceApi.patch(`/finance/${props.isInvoice ? "invoice" : "payment"}-files`, props.transaction_guids)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Replace file request sent.")
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Something went wrong with the replace file request.", { apiError })
      }
    }
  )
}

export const useCalculateAccrualMutation = ({
  onSuccess,
  onError
}: MutationCallbacks<AccrualStats, CalculateAccrualProps>): UseMutationResult<
  AccrualStats,
  ApiError,
  CalculateAccrualProps
> => {
  return useMutation({
    mutationFn: async (props: CalculateAccrualProps): Promise<AccrualStats> => {
      return NewFinanceApi.post(
        `/v1/finance/calculations/execute/accrual/${props.carer_guid}?date=${props.fromDate}&dryRun=false`
      )
    },
    onSuccess,
    onError
  })
}
