import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"
import { DatePicker, DateTimePicker } from "@ceracare/dcp-ui"
import { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"
import WarningMessage from "components/WarningMessage"
import { isNil } from "ramda"

type OnChange = (dateTime?: DateTime | null, ...rest: any) => void

const useOnChange = (
  handleChange: OnChange,
  withTime: boolean,
  warningValidators: (...args: any) => string | undefined,
  setWarningMessage: Dispatch<SetStateAction<string | undefined | null>>
): OnChange => {
  return useCallback(
    (datePickerValue, ...rest) => {
      if (!isNil(warningValidators)) {
        setWarningMessage(warningValidators(datePickerValue))
      }

      const buildDatePickerValue = () => {
        if (!datePickerValue) return null
        if (withTime) return datePickerValue
        return datePickerValue.startOf("day")
      }

      return handleChange(buildDatePickerValue(), ...rest)
    },
    [handleChange, withTime, warningValidators, setWarningMessage]
  )
}

const usePickerValue = (value: any, withTime: boolean, onChange: OnChange) => {
  const pickerValue = useMemo(() => {
    const processDate = (date: DateTime | string): DateTime => {
      if (typeof date === "string") {
        const dateTime = DateTime.fromISO(date)
        return withTime ? dateTime.startOf("day") : dateTime
      }

      if (!withTime) {
        return date.startOf("day")
      }

      return date
    }

    return !!value && (typeof value === "string" || value instanceof DateTime || DateTime.isDateTime(value))
      ? processDate(value)
      : null
  }, [value, withTime])

  useEffect(() => {
    if (value && pickerValue) {
      const dateTimesEqual =
        (value instanceof DateTime || DateTime.isDateTime(value)) && value.toMillis() === pickerValue.toMillis()
      if (!dateTimesEqual) {
        // ensures that time is set to 00:00
        // in case that initial value was not changed
        onChange(pickerValue)
      }
    }
  }, [value, pickerValue, onChange])

  return pickerValue
}

// NOTE(maoc): Find a way how to type this
// DatePicker doesn't return a valid element for FieldRenderProps
type Props = any

const DatePickerWrapper: React.FC<Props> = (props) => {
  const {
    input: { name, onChange: handleChange, value, ...restInput },
    meta,
    fieldState,
    withTime,
    label,
    warningValidators,
    helperText,
    error,
    ...rest
  } = props
  const [warningMessage, setWarningMessage] = useState<string | undefined | null>(null)

  const showError = useMemo(() => {
    // react-final-form
    if (meta) {
      return ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || !!value)
    }
    // react-hook-form
    if (fieldState) {
      return fieldState.invalid
    }
    return false
  }, [meta, value, fieldState])

  const errorMessage = useMemo(() => {
    // react-final-form
    if (meta) {
      return meta.error || meta.submitError
    }
    // react-hook-form
    if (fieldState) {
      return fieldState.error?.message
    }
  }, [meta, fieldState])

  const onChange = useOnChange(handleChange, withTime, warningValidators, setWarningMessage)
  const pickerValue = usePickerValue(value, withTime, onChange)

  const pickerProps = {
    value: pickerValue,
    label,
    onChange,
    slotProps: {
      field: {
        "data-cy": testingId.datePickerField,
        fullWidth: true
      },
      textField: {
        name,
        sx: { mt: 2, mb: 1 },
        helperText: showError ? errorMessage : helperText,
        error: showError,
        ...restInput
      }
    }
  }

  return (
    <>
      {withTime ? (
        <>
          <DateTimePicker {...rest} {...pickerProps} ampm={false} format={DateTimeFormat.DATE_TIME} />
          {!isNil(warningMessage) && <WarningMessage>{warningMessage}</WarningMessage>}
        </>
      ) : (
        <>
          <DatePicker {...rest} {...pickerProps} format={DateTimeFormat.DATE} />
          {!isNil(warningMessage) && <WarningMessage>{warningMessage}</WarningMessage>}
        </>
      )}
    </>
  )
}

export default DatePickerWrapper
